import React from 'react';
import { getThemeComponent } from '@/utils/themeImports';
import { AddPagesLib as _addPageLib } from '@dotpe/additional-pages';
import { specificThemeRender } from '@/utils/specificThemeRender';
import { useSSRSelector } from '@/redux/ssrStore';

const AdditionalPage = (props) => {
  const { storeInfo, catalogCategories, widgetData } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
    catalogCategories: state.catalogReducer.categories,
    widgetData: state.additionalPagesReducer.additionalPageList,
  }));
  const isHomePage = props.source === 'home';
  // const [widgetData, setWidgetData] = useState({}); // if props.source is home then use widgetdata to render

  const _renderUnstructuredPageData = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: props?._addPage?.html_data,
        }}
      />
    );
  };

  const _renderStructuredPageData = () => {
    let dataToRender = props?._addPage?.json_data || {};
    if (isHomePage) {
      // if the source is home then  use state because on home there can be multiple widgets
      dataToRender = widgetData?.json_data || {};
    }
    return dataToRender ? (
      !!Object.keys(dataToRender).length &&
        Object.keys(dataToRender).map((template) => {
          const CompJSX = _addPageLib(template);
          const templateData = dataToRender[template];
          if (props?.config?.max_items)
            templateData['max_items'] = props?.config?.max_items;
          if (props.slug) templateData['slug'] = props.slug;
          return (
            <CompJSX
              section={dataToRender[template]}
              templateId={template}
              source={'cfe'}
              onElementClick={() => {}}
              key={template}
            />
          );
        })
    ) : (
      <></>
    );
  };

  return (
    <div className="relative">
      {!isHomePage &&
        specificThemeRender(
          storeInfo?.theme,
          storeInfo?.store_premium_theme,
          storeInfo?.premium
        )?.components?.Header?.map((layout) => {
          return getThemeComponent(layout.sub_type, layout.name, {
            storeData: storeInfo,
            categories: catalogCategories || [],
            ...layout,
          });
        })}
      <div
        className={`additional--wrapper ${props?.config?.max_items ? 'home-page' : ''}`}
      >
        <section className="additional-main">
          {props._addPage?.structure_type === 0
            ? _renderUnstructuredPageData()
            : _renderStructuredPageData()}
        </section>

        {!isHomePage &&
          specificThemeRender(
            storeInfo?.theme,
            storeInfo?.store_premium_theme,
            storeInfo?.premium
          )?.components?.Footer?.map((layout) => {
            return getThemeComponent(layout.sub_type, layout.name, {
              storeData: storeInfo,
              categories: catalogCategories || [],
              ...layout,
            });
          })}
      </div>
    </div>
  );
};

export default AdditionalPage;
